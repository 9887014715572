import { Container, Typography } from "@mui/material";

const ModePage = () => {
  return (
    <Container maxWidth="md">
      <Typography variant="body1" align="center" style={{ padding: 50 }}>
        Modes work by setting minimum and maximum thresholds for the warnings
        that you receive. Stages work to slice up points in between the minimum
        and maximum to give you more fine turned warnings as you reach the
        minimum and start to approach the maximum amount of calories for the
        day. Below are thresholds for preset modes.
        <br /> Custom mode is coming soon and allows you to set min/max values
        yourself.
        <br />
        <br />
        <br />
        Default Mode: Minimum: 1500, Maximum: 2500
        <br />
        Low Mode: Minimum: 1200, Maximum: 2000
        <br />
        Medium Mode: Minimum: 1500, Maximum: 2500
        <br />
        High Mode: Minimum: 1800, Maximum: 2800
        <br />
      </Typography>
    </Container>
  );
};

export default ModePage;
