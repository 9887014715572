import { Button, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import FormikTextField from "./FormikTextField";
import * as Yup from "yup";
import { useState } from "react";
import {
  confirmForgotPassword,
  forgotPassword as authForgotPassword,
  postLogin,
} from "../Util/auth";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useHistory } from "react-router";
import { FORM_PAPER_ELEVATION, FORM_ROW_SPACING } from "../Util/constants";
const ForgotSchema = Yup.object().shape({
  email: Yup.string().required("You must enter your email."),
});
const NewPasswordSchema = Yup.object().shape({
  code: Yup.string().required("You must enter the code sent to your email."),
  password: Yup.string()
    .required("A password is required.")
    .min(8, "Password is too short, must be 8 characters minimum.")
    .matches(/[a-z]/, "Password must contain a lowercase letter.")
    .matches(/[A-Z]/, "Password must contain an uppercase letter."),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "25px",
    width: "65%",
    marginLeft: "auto",
    marginRight: "auto",
    padding: 50,
  },
  textfieldRoot: {
    width: "100%",
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const [stage, setStage] = useState(0);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>();
  const history = useHistory();
  const formik = useFormik({
    initialValues: { email: "" },
    onSubmit: (values, helper) => {
      setCognitoUser(
        authForgotPassword(
          values.email,
          () => {
            setStage(1);
          },
          () => {
            helper.setErrors({
              email: "No user account is associated with this email.",
            });
          }
        )
      );
    },
    validationSchema: ForgotSchema,
  });
  const formik2 = useFormik({
    initialValues: {
      code: "",
      password: "",
      confirmPassword: "",
    },
    onSubmit: (values, helper) => {
      confirmForgotPassword(
        values.code,
        values.password,
        () => {
          postLogin(history);
        },
        () => {
          helper.setErrors({ code: "Invalid code entered." });
        },
        cognitoUser
      );
    },
    validationSchema: NewPasswordSchema,
  });
  let middleElement = <></>;
  switch (stage) {
    case 0:
      middleElement = (
        <>
          <Grid item>
            <FormikTextField label="Email" name="email" formik={formik} />
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => formik.handleSubmit()}>
              Submit
            </Button>
          </Grid>
        </>
      );
      break;
    case 1:
      middleElement = (
        <>
          <Grid item>
            <Typography align="center">
              Please check the email you entered for a confirmation code.
            </Typography>
          </Grid>
          <Grid item>
            <FormikTextField label="Code" name="code" formik={formik2} />
          </Grid>
          <Grid item>
            <FormikTextField
              label="Password"
              name="password"
              type="password"
              formik={formik2}
            />
          </Grid>
          <Grid item>
            <FormikTextField
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              formik={formik2}
            />
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => formik.handleSubmit()}>
              Resend Code
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => formik2.handleSubmit()}>
              Submit
            </Button>
          </Grid>
        </>
      );
      break;
    default:
      break;
  }
  return (
    <Paper elevation={FORM_PAPER_ELEVATION}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        rowSpacing={FORM_ROW_SPACING}
        className={classes.root}
      >
        {middleElement}
      </Grid>
    </Paper>
  );
};

export default ForgotPassword;
