import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../Redux/hooks";
import { getUsersName, isAuthed, logout } from "../Redux/slices/user";
import { CustomTheme, hideMobile, showMobile } from "../Util/Theme";

const useStyles = makeStyles((theme: CustomTheme) => ({
  containerRoot: {
    width: "fit-content !important",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
  itemRoot: {
    padding: 5,
  },
  responsiveNavItem: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  hideMobile: hideMobile(theme),
  showMobile: showMobile(theme),
}));

export interface INavBarProps {}
const NavBar = (props: INavBarProps) => {
  const classes = useStyles();
  const history = useHistory();
  const isAuthenticated = useAppSelector(isAuthed);
  const name = useAppSelector(getUsersName);
  const dispatch = useAppDispatch();

  return (
    <header>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid container direction="row" className={classes.containerRoot}>
          <Grid item className={classes.responsiveNavItem}>
            <Typography className={classes.responsiveNavItem} align="center">
              LOGO <br />
              HERE
            </Typography>
          </Grid>
          <Grid item className={classes.responsiveNavItem}>
            <Button
              className={classes.responsiveNavItem}
              size="large"
              onClick={() => {
                history.push("/");
              }}
            >
              Home
            </Button>
          </Grid>
          <Grid item className={classes.responsiveNavItem}>
            <Button
              className={classes.responsiveNavItem}
              size="large"
              onClick={() => {
                history.push("/tracker");
              }}
            >
              Tracker
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          className={classes.containerRoot + " " + classes.hideMobile}
        >
          <Grid item className={classes.itemRoot}>
            {isAuthenticated ? (
              <Typography style={{ lineHeight: "46px" }}>
                Welcome, {name}
              </Typography>
            ) : (
              <Button
                variant="outlined"
                size="large"
                onClick={() => {
                  history.push("/sign-up");
                }}
              >
                Sign Up
              </Button>
            )}
          </Grid>
          <Grid item className={classes.itemRoot}>
            {isAuthenticated ? (
              <Button
                size="large"
                variant="outlined"
                onClick={() => dispatch(logout())}
              >
                Sign Out
              </Button>
            ) : (
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  history.push("/login");
                }}
              >
                Login
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          className={classes.containerRoot + " " + classes.showMobile}
        >
          <Grid item className={classes.responsiveNavItem}>
            {isAuthenticated ? (
              <Button
                size="large"
                className={classes.responsiveNavItem}
                onClick={() => dispatch(logout())}
              >
                Sign Out
              </Button>
            ) : (
              <Button
                size="large"
                className={classes.responsiveNavItem}
                onClick={() => {
                  history.push("/login");
                }}
              >
                Login
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </header>
  );
};

export default NavBar;
