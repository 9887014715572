import { Typography } from "@mui/material";

const HomePage = () => {
  return (
    <Typography variant="h2" align="center">
      HOMEPAGE TBD
    </Typography>
  );
};

export default HomePage;
