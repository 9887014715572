import CalorieTracker from "./Components/CalorieTracker";
import { Switch, Route } from "react-router-dom";
import NavBar from "./Components/NavBar";
import HomePage from "./Views/HomePage";
import LoginPage from "./Views/LoginPage";
import SignUpPage from "./Views/SignUpPage";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import {
  extractTokenInfo,
  getUserFromStorage,
  mapTokenInfo,
} from "./Util/auth";
import { useAppDispatch } from "./Redux/hooks";
import { login } from "./Redux/slices/user";
import ForgotPasswordPage from "./Views/ForgotPasswordPage";
import ModePage from "./Views/ModesPage";

const useStyles = makeStyles((theme) => ({
  root: { minHeight: "100vh", marginBottom: 50 },
}));

function App() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  useEffect(() => {
    getUserFromStorage().then((session) => {
      const tokenInfo = extractTokenInfo(session.getIdToken());
      dispatch(login(mapTokenInfo(tokenInfo)));
    });
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <NavBar />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/tracker" component={CalorieTracker} />
        <Route path="/login" component={LoginPage} />
        <Route path="/sign-up" component={SignUpPage} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/modes" component={ModePage} />
      </Switch>
    </div>
  );
}

export default App;
