import { Button, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../Redux/hooks";
import { isAuthed, login } from "../Redux/slices/user";
import {
  extractTokenInfo,
  login as authLogin,
  mapTokenInfo,
} from "../Util/auth";
import FormikTextField from "./FormikTextField";
import { Redirect } from "react-router-dom";
import { FORM_PAPER_ELEVATION, FORM_ROW_SPACING } from "../Util/constants";

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("You must enter a valid username!"),
  password: Yup.string().required("You must enter a valid password!"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "25px",
    width: "65%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  signUpText: {
    color: "#A9A9A9",
    width: "100%",
    marginTop: "50px !important",
    display: "block",
  },
  textfieldRoot: {
    width: "100%",
  },
}));

const LoginComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(isAuthed);
  const formik = useFormik({
    onSubmit: async (values) => {
      const userSession = await authLogin(values);
      const tokenInfo = extractTokenInfo(userSession.getIdToken());
      dispatch(login(mapTokenInfo(tokenInfo)));
    },
    initialValues: { username: "", password: "" },
    validationSchema: LoginSchema,
  });

  if (isAuthenticated) return <Redirect to="/" />;

  return (
    <Paper elevation={FORM_PAPER_ELEVATION}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        rowSpacing={FORM_ROW_SPACING}
        className={classes.root}
      >
        <Grid item className={classes.textfieldRoot} xs={10}>
          <FormikTextField
            fullWidth
            label="Username"
            name="username"
            formik={formik}
          />
        </Grid>
        <Grid item className={classes.textfieldRoot}>
          <FormikTextField
            fullWidth
            type="password"
            label="Password"
            name="password"
            formik={formik}
          />
          <Typography variant="caption">
            <Link to="forgot-password">Forgot Password?</Link>
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={() => formik.handleSubmit()} variant="contained">
            Login
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Typography
          align="center"
          variant="caption"
          className={classes.signUpText}
        >
          Don't have an account? <Link to="/sign-up"> Sign Up!</Link>
        </Typography>
      </Grid>
    </Paper>
  );
};

export default LoginComponent;
