import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LoginComponent from "../Components/Login";
import { Center } from "../Util/css";

const useStyles = makeStyles((theme) => ({
  root: { ...Center },
}));

const LoginPage = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="sm" className={classes.root}>
      <LoginComponent />
    </Container>
  );
};

export default LoginPage;
