import { createTheme } from "@mui/material";
import { Theme } from "@mui/system";

export const theme = createTheme({});

export interface CustomTheme extends Theme {}

export const hideMobile = (theme: CustomTheme) => {
  return {
    [theme.breakpoints.down("sm")]: { display: "none" },
  };
};

export const showMobile = (theme: CustomTheme) => {
  return {
    display: "none",
    [theme.breakpoints.down("sm")]: { display: "inherit" },
  };
};
