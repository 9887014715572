import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../Redux/hooks";
import { isAuthed } from "../Redux/slices/user";
import axios from "../Util/axios";
import { CustomTheme } from "../Util/Theme";
import { Meal, Mode } from "../Util/types";
import * as constants from "../Util/constants";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: CustomTheme) => ({
  inputItem: { marginTop: 25, width: 220 },
  root: { marginTop: 25 },
  statusText: { padding: 25 },
  settingsContainer: {
    border: "solid 1px grey",
    width: "fit-content",
    padding: 50,
    paddingTop: 10,
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
  item: {
    width: "fit-content",
    margin: 15,
  },
  formControl: {
    width: 275,
  },
}));

const getStatus = (idx: number, stages: number) => {
  const statusObj = { statusText: "", textColor: "" };
  switch (stages) {
    case 3:
      switch (idx) {
        case 0:
          return constants.NO_WARNING;
        case 1:
          return constants.MID_WARNING;
        case 2:
          return constants.OVER_WARNING;
        default:
          return statusObj;
      }
    case 4:
      switch (idx) {
        case 0:
          return constants.NO_WARNING;
        case 1:
          return constants.LOW_WARNING;
        case 2:
          return constants.MID_WARNING;
        case 3:
          return constants.OVER_WARNING;
        default:
          return statusObj;
      }
    case 5:
      switch (idx) {
        case 0:
          return constants.NO_WARNING;
        case 1:
          return constants.LOW_WARNING;
        case 2:
          return constants.MID_WARNING;
        case 3:
          return constants.HIGH_WARNING;
        case 4:
          return constants.OVER_WARNING;
        default:
          return statusObj;
      }
    default:
      return statusObj;
  }
};

const parseStatus = (
  mode: Mode,
  calories: number,
  stages: number,
  customMin?: number,
  customMax?: number
) => {
  const min = customMin || constants.MODE_OBJECT[mode].min;
  const max = customMax || constants.MODE_OBJECT[mode].max;
  const diff = max - min;
  const stepAmount = Math.floor(diff / (stages - 1));
  for (let i = 0; i < stages - 1; i++) {
    const lowerThreshold = stepAmount * i;
    const upperThreshold = stepAmount * (i + 1);
    if (calories > min + lowerThreshold && calories < min + upperThreshold)
      return getStatus(i + 1, stages);
  }
  return getStatus(0, stages);
};

const CalorieTracker = () => {
  const classes = useStyles();
  const [calories, setCalories] = useState(0);
  const [enteredCalories, setEnteredCalories] = useState<number | null>();
  const [isLoading, setIsLoading] = useState(true);
  const [mode, setMode] = useState<Mode>(
    (localStorage.getItem("mode") as Mode) || "default"
  );
  const [stages, setStages] = useState(
    parseInt(localStorage.getItem("stages") || "3")
  );
  const isAuthenticated = useAppSelector(isAuthed);

  useEffect(() => {
    if (!isAuthenticated) {
      setIsLoading(false);
      return;
    }
    const getMeals = async () => {
      const meals = await axios
        .get("/meals/today", {
          params: {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        })
        .then((resp) => resp.data);

      const calorieCount = (meals as Array<Meal>).reduce(
        (acc, curr) => acc + curr.calories,
        0
      );
      setCalories(calorieCount);
      setIsLoading(false);
    };
    getMeals();
  }, [isAuthenticated]);

  const { statusText, textColor } = useMemo(
    () => parseStatus(mode, calories, stages),
    [mode, calories, stages]
  );

  if (isLoading) return <CircularProgress />;

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      {isAuthenticated ? (
        <></>
      ) : (
        <Grid item>
          <Alert severity="warning">
            You are not logged in so anything you enter will not be saved when
            you leave!
          </Alert>
        </Grid>
      )}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item className={classes.inputItem}>
          <TextField
            fullWidth
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (isNaN(parseInt(e.target.value))) return;
              setEnteredCalories(parseInt(e.target.value));
            }}
            value={enteredCalories || ""}
            label="Enter Calories from meal"
          />
        </Grid>
        <Grid item>
          <Button
            className={classes.inputItem}
            variant="contained"
            onClick={async () => {
              axios.post("/meals/create", { calories: enteredCalories });
              setCalories(calories + (enteredCalories || 0));
              setEnteredCalories(null);
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Typography
          className={classes.statusText}
          align="center"
          variant="h3"
          style={{ color: textColor }}
        >
          {statusText}
        </Typography>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.settingsContainer}
      >
        <Grid item className={classes.item}>
          <Typography align="center" variant="h4">
            Settings
          </Typography>
        </Grid>
        <Grid item className={classes.item}>
          <FormControl className={classes.formControl}>
            <InputLabel id="mode-label">Mode</InputLabel>
            <Select
              labelId="mode-label"
              value={mode}
              label="Mode"
              onChange={(e: SelectChangeEvent<Mode>) => {
                localStorage.setItem("mode", e.target.value.toString());
                setMode(e.target.value as Mode);
              }}
            >
              <MenuItem value={"default"}>Default</MenuItem>
              <MenuItem value={"low"}>Low</MenuItem>
              <MenuItem value={"medium"}>Medium</MenuItem>
              <MenuItem value={"high"}>High</MenuItem>
            </Select>
            <FormHelperText>
              Have questions about modes? <Link to="/modes">Click Here!</Link>
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item className={classes.item}>
          <FormControl className={classes.formControl}>
            <InputLabel id="stage-label">Stages</InputLabel>
            <Select
              labelId="stage-label"
              value={stages}
              label="Stages"
              onChange={(e: SelectChangeEvent<number>) => {
                localStorage.setItem("stages", e.target.value.toString());
                setStages(e.target.value as number);
              }}
            >
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CalorieTracker;
