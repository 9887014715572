import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { signOut } from "../../Util/auth";
import type { RootState } from "../store";

// Define a type for the slice state
interface userState {
  isAuthenticated: boolean;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
}

// Define the initial state using that type
const initialState: userState = {
  isAuthenticated: false,
  firstName: "",
  lastName: "",
  email: "",
  username: "",
};

export type UserInfo = Omit<userState, "isAuthenticated">;

export type LoginPayload = {
  email: string;
  password: string;
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      Object.keys(initialState).forEach((key) => {
        (state as Record<string, any>)[key] = (
          initialState as Record<string, any>
        )[key];
      });
      signOut();
    },
    login: (state, action: PayloadAction<UserInfo>) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.username = action.payload.username;
      state.isAuthenticated = true;
    },
  },
});

export const { logout, login } = userSlice.actions;

export const isAuthed = (state: RootState) => state.user.isAuthenticated;

export const getUsersName = (state: RootState) =>
  state.user.firstName + " " + state.user.lastName;

export default userSlice.reducer;
