import { Mode } from "./types";

export const FORM_PAPER_ELEVATION = 10;
export const FORM_ROW_SPACING = 4;

export const NO_WARNING = {
  statusText: "You can eat plenty more food!",
  textColor: "green",
};
export const LOW_WARNING = {
  statusText: "You can eat some more food!",
  textColor: "#8B8000",
};
export const MID_WARNING = {
  statusText: "You have reached optimal calories for today!",
  textColor: "red",
};
export const HIGH_WARNING = {
  statusText: "You have reached peak calories for today.",
  textColor: "darkred",
};
export const OVER_WARNING = {
  statusText: "You have gone over peak calories for today.",
  textColor: "black",
};

export const MODE_OBJECT: Record<Mode, Record<"min" | "max", number>> = {
  default: { min: 1500, max: 2500 },
  low: { min: 1200, max: 2000 },
  medium: { min: 1500, max: 2500 },
  high: { min: 1800, max: 2800 },
  custom: { min: 0, max: 0 },
};
