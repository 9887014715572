import { CSSProperties } from "@mui/material/styles/createMixins";

export const VerticalCenter: CSSProperties = {
  margin: 0,
  position: "absolute",
  top: "50%",
  "-ms-transform": "translateY(-50%)",
  transform: "translateY(-50%)",
};

export const Center: CSSProperties = {
  margin: 0,
  position: "absolute",
  top: "50%",
  left: "50%",
  "-ms-transform": "translate(-50%, -50%)",
  transform: "translate(-50%, -50%)",
};
