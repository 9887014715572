import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
  CognitoIdToken,
} from "amazon-cognito-identity-js";
import { UserInfo } from "../Redux/slices/user";
import jwt_decode from "jwt-decode";

const poolData = {
  ClientId: "560ap8nj72imo0207rgu8iqg5c",
  UserPoolId: "us-east-1_xxodTmKqk",
};

const userPool = new CognitoUserPool(poolData);

let refreshInterval: any = 0;

const refreshAccess = (session: CognitoUserSession) => {
  const cognitoUser = userPool.getCurrentUser();
  cognitoUser?.refreshSession(session.getRefreshToken(), (err, result) => {
    console.log("Refreshed id token");
    localStorage.setItem("access_token", result.getIdToken().getJwtToken());
  });
};

export type SignUpPayload = {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

export type ConfirmationPayload = {
  user?: CognitoUser;
  code: string;
};

export type LoginPayload = {
  username: string;
  password: string;
};

export const signUp = ({
  username,
  firstName,
  lastName,
  email,
  password,
}: SignUpPayload): Promise<CognitoUser | undefined> => {
  const dataGivenName = {
    Name: "given_name",
    Value: firstName,
  };
  const dataFamilyName = {
    Name: "family_name",
    Value: lastName,
  };
  const dataEmail = {
    Name: "email",
    Value: email,
  };
  const attributeList = [dataGivenName, dataFamilyName, dataEmail];
  const attributeData = attributeList.map((x) => new CognitoUserAttribute(x));
  return new Promise((res, rej) => {
    userPool.signUp(username, password, attributeData, [], (err, result) => {
      if (err) rej(err);
      else res(result?.user);
    });
  });
};

export const confirmRegistration = ({ user, code }: ConfirmationPayload) => {
  return new Promise((res, rej) => {
    user?.confirmRegistration(code, true, (err, result) => {
      if (err) rej(err);
      else res(result);
    });
  });
};

export const resendCode = ({ user }: ConfirmationPayload) => {
  user?.resendConfirmationCode((err, result) => {});
};

export const login = ({
  username,
  password,
}: LoginPayload): Promise<CognitoUserSession> => {
  const authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  });
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);

  return new Promise((res, rej) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        localStorage.setItem("access_token", result.getIdToken().getJwtToken());
        clearInterval(refreshInterval);
        refreshInterval = setInterval(() => {
          refreshAccess(result);
        }, 3500000);
        res(result);
      },
      onFailure: function (err) {
        alert(err.message || JSON.stringify(err));
      },
    });
  });
};

export const signOut = () => {
  const cognitoUser = userPool.getCurrentUser();
  cognitoUser?.signOut();
  localStorage.removeItem("access_token");
};

export const getUserFromStorage = (): Promise<CognitoUserSession> => {
  const cognitoUser = userPool.getCurrentUser();
  return new Promise((res, rej) => {
    if (!!cognitoUser) {
      cognitoUser.getSession((err: any, session: CognitoUserSession) => {
        localStorage.setItem(
          "access_token",
          session.getIdToken().getJwtToken()
        );
        clearInterval(refreshInterval);
        refreshInterval = setInterval(() => {
          refreshAccess(session);
        }, 3500000);
        if (err) rej(err);
        else res(session);
      });
    } else {
      rej("No user session found!");
    }
  });
};

export const extractAttributes = (
  cognitoUser?: CognitoUser
): Promise<UserInfo> => {
  return new Promise((res, rej) => {
    cognitoUser?.getUserAttributes((err, result) => {
      console.log(err, result);
      if (err) return;
      const attributes: Record<string, any> = {};
      result?.forEach((attribute) => {
        attributes[attribute.getName()] = attribute.getValue();
      });
      console.log(attributes);
    });
  });
};

export const extractTokenInfo = (
  token: CognitoIdToken
): Record<string, any> => {
  const tokenInfo: Record<string, any> = jwt_decode(token.getJwtToken());
  return tokenInfo;
};

export const mapTokenInfo = (tokenInfo: Record<string, any>) => {
  return {
    username: tokenInfo["cognito:username"],
    firstName: tokenInfo.given_name,
    lastName: tokenInfo.family_name,
    email: tokenInfo.email,
  };
};

export const forgotPassword = (
  email: string,
  onSuccess: () => void,
  onFailure: () => void
) => {
  const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
  cognitoUser.forgotPassword({
    onSuccess,
    onFailure,
  });
  return cognitoUser;
};

export const confirmForgotPassword = (
  code: string,
  newPassword: string,
  onSuccess: () => void,
  onFailure: () => void,
  cognitoUser?: CognitoUser
) => {
  cognitoUser?.confirmPassword(code, newPassword, { onSuccess, onFailure });
};

export const postLogin = (history: any) => {
  history.push("/");
};
