import { Button, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import FormikTextField from "./FormikTextField";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { confirmRegistration, signUp } from "../Util/auth";
import { Redirect } from "react-router-dom";
import { useAppSelector } from "../Redux/hooks";
import { isAuthed } from "../Redux/slices/user";
import { FORM_PAPER_ELEVATION, FORM_ROW_SPACING } from "../Util/constants";

const SignUpSchema = Yup.object().shape({
  username: Yup.string().required("You must enter a unique username!"),
  firstName: Yup.string().required("You must enter a first name."),
  lastName: Yup.string().required("You must enter a last name."),
  email: Yup.string().required("You must enter an email."),
  password: Yup.string()
    .required("A password is required.")
    .min(8, "Password is too short, must be 8 characters minimum.")
    .matches(/[a-z]/, "Password must contain a lowercase letter.")
    .matches(/[A-Z]/, "Password must contain an uppercase letter."),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

const ConfirmSchema = Yup.object().shape({
  code: Yup.string()
    .matches(/[0-9]/, "Code must only be numbers.")
    .required("Please enter the code sent to your email."),
});

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "25px",
    width: "65%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  signUpText: {
    color: "#A9A9A9",
    width: "100%",
    marginTop: "50px",
    display: "block",
  },
  buttonRoot: { marginTop: "-25px" },
  textfieldRoot: {
    width: "100%",
  },
}));

const SignUpComponent = () => {
  const classes = useStyles();
  const [stage, setStage] = useState(0);
  const [user, setUser] = useState<CognitoUser | undefined>(undefined);
  const history = useHistory();
  const isAuthenticated = useAppSelector(isAuthed);
  const formik = useFormik({
    onSubmit: (values) => {
      signUp(values)
        .then((cognitoUser) => {
          setStage(1);
          setUser(cognitoUser);
        })
        .catch((err) => {
          if (err.message === "User already exists") {
            formik.setErrors({
              username:
                "Username is already taken. Please use a different username.",
            });
          }
        });
    },
    initialValues: {
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: SignUpSchema,
  });
  const formik2 = useFormik({
    onSubmit: async (values) => {
      await confirmRegistration({ user, ...values });
      //const userInfo = await extractAttributes(user);
      //dispatch(login(userInfo));
      //console.log("moving to tracker page");
      history.push("/login");
    },
    initialValues: { code: "" },
    validationSchema: ConfirmSchema,
  });
  if (isAuthenticated) return <Redirect to="/" />;
  let middleElement = <></>;
  switch (stage) {
    case 0:
      middleElement = (
        <>
          <Grid item className={classes.textfieldRoot}>
            <FormikTextField
              fullWidth
              label="Username"
              name="username"
              formik={formik}
            />
          </Grid>
          <Grid item className={classes.textfieldRoot}>
            <FormikTextField
              fullWidth
              label="Email"
              name="email"
              formik={formik}
            />
          </Grid>
          <Grid item className={classes.textfieldRoot}>
            <FormikTextField
              fullWidth
              label="First Name"
              name="firstName"
              formik={formik}
            />
          </Grid>
          <Grid item className={classes.textfieldRoot}>
            <FormikTextField
              fullWidth
              label="Last Name"
              name="lastName"
              formik={formik}
            />
          </Grid>
          <Grid item className={classes.textfieldRoot}>
            <FormikTextField
              fullWidth
              label="Password"
              name="password"
              type="password"
              formik={formik}
            />
          </Grid>
          <Grid item className={classes.textfieldRoot}>
            <FormikTextField
              fullWidth
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              formik={formik}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => formik.handleSubmit()}>
              Sign Up
            </Button>
          </Grid>
        </>
      );
      break;
    case 1:
      middleElement = (
        <>
          <Grid item>
            <Typography align="center">
              Please check the email you used to sign up for a confirmation
              code.
            </Typography>
          </Grid>
          <Grid item>
            <FormikTextField
              label="Confirmation Code"
              name="code"
              formik={formik2}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              onClick={() => formik2.handleSubmit()}
              className={classes.buttonRoot}
            >
              Submit
            </Button>
          </Grid>
        </>
      );
      break;
    default:
      middleElement = <></>;
      break;
  }
  return (
    <Paper elevation={FORM_PAPER_ELEVATION}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        rowSpacing={FORM_ROW_SPACING}
        className={classes.root}
      >
        {middleElement}
      </Grid>
      <Grid container direction="row">
        <Typography
          align="center"
          variant="caption"
          className={classes.signUpText}
        >
          Already have an account? <Link to="/login"> Login Here!</Link>
        </Typography>
      </Grid>
    </Paper>
  );
};
export default SignUpComponent;
