import ax from "axios";

const baseURL =
  process.env.NODE_ENV === "development"
    ? undefined
    : "https://api.calorie.tibblist.com";

let axios = ax.create({
  baseURL,
});
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token && config.headers) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (e) => Promise.reject(e)
);
export default axios;
