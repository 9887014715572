import { TextField, TextFieldProps } from "@mui/material";

export type IFormikTextFieldProps = {
  formik: Record<string, any>;
  name: string;
} & TextFieldProps;
const FormikTextField = (props: IFormikTextFieldProps) => {
  const { name, formik, ...otherProps } = props;
  return (
    <TextField
      {...otherProps}
      name={props.name}
      value={formik.values[props.name]}
      onChange={formik.handleChange}
      error={formik.touched[props.name] && Boolean(formik.errors[props.name])}
      helperText={formik.touched[props.name] && formik.errors[props.name]}
    />
  );
};

export default FormikTextField;
