import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SignUpComponent from "../Components/SignUp";
import { Center } from "../Util/css";
import { CustomTheme } from "../Util/Theme";

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: { [theme.breakpoints.up("sm")]: { ...Center } },
}));

const SignUpPage = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="sm" className={classes.root}>
      <SignUpComponent />
    </Container>
  );
};

export default SignUpPage;
